import authLogo from './assets/authLogo.png'
import companyLogo from './assets/companyLogo.svg'
import logoFull from './assets/logo-full_l.svg'
import logoFull_M from './assets/logo-full_m.svg'
import logo from './assets/logo.svg'
import './palette.css'

window.PLATFORM = {
  passwordMinLength: 10,
  PlatformName: 'Parta.io',
  announcerDefaultLink: 'https://updates.parta.io',
  knowledgeBase: 'https://help.parta.io/',
  emailDomain: 'parta.io',
  hubSpotChatPath: 'https://js-na1.hs-scripts.com/44139228.js',
  hubSpotContactSalesPath: 'https://meetings.hubspot.com/a-romanovskiy/demo-parta-io?embed=true',
  authBG:
    'linear-gradient(218.31deg, #ececec 0%, rgba(236, 236, 236, 0.43) 42.19%, rgba(236, 236, 236, 0.63) 77.08%, rgba(236, 236, 236, 0.46) 100%)',
  termsOfService:
    'https://terms-service-and-privacy-policy.s3.amazonaws.com/Parta/2024_Parta.io_Terms_of_Service.pdf',
  privacyPolicy:
    'https://terms-service-and-privacy-policy.s3.amazonaws.com/Parta/2024_Parta.io_Privacy_Policy.pdf',
  trialLink: 'https://parta.io/',
  hasLmsIntegration: true,
  allowSignup: true,
  showPpAndTOS: true,
  authLogo,
  companyLogo,
  logo,
  logoFull,
  logoFull_M,
  courseHtmlFooter: '<b>Created in Parta.io.</b> Collaborative eLearning creation platform.',
  fallbackLng: 'en',
  phraseIntegrationGuideLink: 'https://help.parta.io/course-builder/phrase-integration-for-localization-and-ai-translation/connect-to-phrase-via-oauth-2.0'
}
